<template>
  <v-card>
    <div class="d-flex flex-no-wrap justify-start">
      <v-avatar
        tile
        left
        size="100"
        color="#F7F7F7"
        class="ma-3 team-image"
      >
        <v-img
          v-if="data.image"
          :src="data.image"
          contain
          max-height="70%"
          @error="imageErrorHandler()"
        />

        <span
          v-else
          class="display-2 white--text text-center"
        >
          <v-icon>
            mdi-soccer
          </v-icon>
        </span>
      </v-avatar>
      <div>
        <v-card-title v-text="data.name" />

        <v-card-actions>
          <v-btn
            class="ml-2"
            outlined
            small
            color="primary lighten2"
            @click="update"
          >
            <v-icon size="20">
              mdi-pencil
            </v-icon>
          </v-btn>

          <!-- <v-btn
            class="ml-2"
            color="error lighten2"
            :disabled="loading"
            outlined
            small
            @click="remove"
          >
            <v-icon size="20">
              mdi-delete
            </v-icon>
          </v-btn> -->
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      selected: null
    }
  },
  methods: {
    remove () {
      this.$emit('deleteSelected', this.data._id)
    },
    update () {
      this.$emit('updateSelected', this.data._id)
    },
    imageErrorHandler () {
      this.data.image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.team-image {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}
</style>
